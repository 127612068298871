import constants from '@/app-configs/constants'

export default [
  {
    title: 'DEBTIA_HOME',
    route: 'home',
    icon: 'HomeIcon',
    action: constants.action.permissionLogin,
    resource: constants.resource.user,
  },
  {
    title: 'DEBTIA_TRANSLATIONS',
    route: 'translations',
    icon: 'GridIcon',
    action: constants.action.permissionTranslationView,
    resource: constants.resource.translation,
    show: true,
  },
  {
    title: 'DEBTIA_ROLE_MANAGEMENT',
    route: 'role-management',
    icon: 'GridIcon',
    action: constants.action.permissionRoleView,
    resource: constants.resource.role,
    show: true,
  },
]
